import { StyledButton } from "./styles";
import { ButtonProps } from "../types";

export const Button = ({
  color,
  fixedWidth,
  children,
  onClick,
}: ButtonProps) => (
  <StyledButton color={color} fixedWidth={fixedWidth} onClick={() => {window.location.href = 'https://wa.me/628562967403?text=saya mau apdetin'}}>
    {children}
  </StyledButton>
);
